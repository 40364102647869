import React, { useEffect, useState, lazy } from "react";
import "./App.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import detectBrowserLanguage from "detect-browser-language";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import de from "javascript-time-ago/locale/de";
import Helper from "./utils/Helper";
import WithSuspense from "./components/suspense/WithSuspense";

class AppComponent extends React.Component {
  /**
   * The constructor of the class.
   * This built-in method is called before anything else, when the component is initiated,
   * and it is the natural place to set up the initial state and other initial values.
   * The constructor() method is called with the props, as arguments,
   * and we should always start by calling the super(props) before anything else,
   * this will initiate the parent's constructor method and allows the component to inherit methods from its parent (React.Component).
   * @param {object} props the initial values of the props that passed to the class by its caller
   */
  constructor(props) {
    super(props);
    this.state = {
      pageRef: null,
      user: Helper.getUser(),
      location: null,
      history: null,
      translation: null,
    };
    if (!Helper.getS3BaseURL()) {
      this.s3BaseURL = Helper.fetchS3BaseURL();
    }
    this.userUpdatedHandler = this.userUpdatedHandler.bind(this);
  }

  /**
   * This handler will be called when ever user gets updated
   */
  userUpdatedHandler() {
    this.setState({
      user: Helper.getUser(),
    });
  }

  /**
   * This built-in method is called right before rendering the element(s) in the DOM.
   * This is the natural place to set the state object based on the initial props.
   * It takes state as an argument, and returns an object with changes to the state.
   * @param {object} props the new values of the props
   * @param {object} state the current values of the state
   * @returns {object} new value for state object
   */
  static getDerivedStateFromProps(props, state) {
    return {
      pageRef: props.pageRef,
      location: props.location,
      history: props.history,
      translation: props.translation,
    };
  }

  /**
   * This built-in method is called after the component is rendered.
   * This is where we run statements that requires that the component is already placed in the DOM.
   */
  componentDidMount() {
    // Set the translation language based on browser language first
    // This part of the code is executed only once.
    let locale = this._getBrowserLanguage();
    const { user } = this.state;
    if (user) {
      // Maybe the locale is changed here based on user language
      locale = user.locale ? user.locale : locale;
      // Listen for any changed in user details
      Helper.attachOnUserUpdated(this.userUpdatedHandler.bind(this));
    }
    this.setTranslationLocale(locale);
  }

  /**
   * This built-in method is called when the component is about to be removed from the DOM.
   * This is where we clean up or browser events.
   */
  componentWillUnmount() {
    // Remove listener
    Helper.detachOnUserUpdated(this.userUpdatedHandler.bind(this));
  }

  /**
   * Detects and determines browser language
   */
  _getBrowserLanguage() {
    let locale = detectBrowserLanguage();
    if (locale && locale.length >= 2) {
      locale = locale.toLowerCase().substring(0, 2);
    } else {
      locale = "en";
    }
    return locale;
  }

  /**
   * Sets the translation locale
   * @param {string} locale The intended locale to be present the view based on that
   */
  setTranslationLocale(locale) {
    const {
        translation: { i18n },
      } = this.state,
      currentLocale = i18n.language || window.localStorage.i18nextLng || "";
    if (currentLocale !== locale) {
      i18n.changeLanguage(locale);
    }
  }

  /**
   * A function to load the component lazy
   * @param {function} factory A function that imports and return the component
   * @returns A lazy loading component
   */
  lazyWithPreload(factory) {
    const Component = lazy(factory);
    Component.preload = factory;
    return Component;
  }

  /**
   * This built-in method is required, and is the method that actually outputs the HTML to the DOM.
   * In this case it checks if it is needed to redirect the location or not.
   * When user logged in successfully then it must redirect, otherwise it will show the login page.
   */
  render() {
    const { user, history, location, pageRef } = this.state;
    const S3BaseURL = () => {
      if (!this.s3BaseURL) {
        return <></>;
      }
      // Try to read S3 Base URL info, although it might not have loaded yet to cause to suspense on the whole page
      const result = this.s3BaseURL.result.read();
      return <>{result.url ? "" : ""}</>;
    };
    if (!user) {
      const Authentication = this.lazyWithPreload(() =>
        import("./components/auth/Authentication")
      );
      return (
        <WithSuspense
          Component={Authentication}
          props={{ location, history, pageRef }}
        />
      );
    } else {
      let { locale } = user;
      // set TimeAgo language based on user language in each render
      if (locale === "de") {
        TimeAgo.addLocale(de);
        TimeAgo.setDefaultLocale("de");
      } else {
        TimeAgo.addLocale(en);
        TimeAgo.setDefaultLocale("en");
      }
      const Root = this.lazyWithPreload(() => import("./components/root/Root"));
      return (
        <>
          <WithSuspense
            Component={Root}
            props={{ location, history, pageRef, user }}
          />
          <S3BaseURL />
        </>
      );
    }
  } // end of render function
} // end of app class

/**
 * This is the place that we call the hoocks and pass them as props to the clas.
 */
export default function App(props) {
  const translation = useTranslation(["translation"]);
  const history = useHistory();
  const [location, setLocation] = useState(useLocation());
  useEffect(() => {
    return history.listen((location) => {
      setLocation(location);
    });
  }, [history]);
  return (
    <AppComponent
      location={location}
      history={history}
      translation={translation}
      {...props}
    />
  );
}
