/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:c3d9b2bd-6b58-4c49-ab71-d10baabd2d8e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_9cuOizxWG",
    "aws_user_pools_web_client_id": "rir0ljg24gn6pmf33j9reqij7",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://ees64smuqbgo7dex7eoms6yasq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "s3presigninapi",
            "endpoint": "https://74s44a6mg3.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
