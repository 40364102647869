import "./css/w3.css";
import "./css/w3-theme.scss";
import React, { StrictMode } from "react";
import "./index.scss";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import App from "./App";
import WithSuspense from "./components/suspense/WithSuspense";
import reportWebVitals from "./reportWebVitals";
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Amplify from "aws-amplify";
import config from "./aws-exports";
Amplify.configure(config);
// To access the page container in the child components we pass it to app. 
const pageRef = React.createRef();
// 
ReactDOM.render(
  <StrictMode>
    <div id="page-container" ref={pageRef}>
      <BrowserRouter>
        <WithSuspense Component={App} props={{pageRef}} />
      </BrowserRouter>
    </div>
  </StrictMode>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
