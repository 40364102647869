import jwt_decode from "jwt-decode";
import { API } from "aws-amplify";
// API name
const S3_API_NAME = "s3presigninapi";
/**
 * The toekn and user constant keys to be used for the session storage.
 */
const UNAUTH_USER_REDIRECT_HREF = "ReactAmplify.RedirectHref";
const AUTH_USER_TOKEN_KEY = "ReactAmplify.TokenKey";
const AUTH_USER_KEY = "ReactAmplify.User";
const AUTH_USER_GROUP_KEY = "ReactAmplify.Groups";
const S3_BASE_URL_KEY = "ReactAmplify.S3BaseURL";
const aUserUpdatedCallbacks = [];
/**
 * helper method to validate  jwt token
 * @private
 * @param {any} token The string as jwt token to be validated
 * @returns {boolean} true is the passed toke is valid, false otherwise
 */
const _validateToken = (token) => {
  if (!token) {
    return false;
  }
  try {
    const decodedJwt = jwt_decode(token);
    return decodedJwt.exp >= Date.now() / 1000;
  } catch (e) {
    return false;
  }
};

/**
 * It will be called when user has been updated.
 * It will call all the registered callback functions.
 */
const _callUserUpdatedCallbacks = () => {
  for (let i = 0; i < aUserUpdatedCallbacks.length; i++) {
    try {
      let fn = aUserUpdatedCallbacks[i];
      if (typeof fn === "function") {
        fn();
      }
    } catch (e) {}
  }
};

/**
 * Checks either promise resolved or not.
 * If not resolved throws error.
 * It uses to provide suspense until data loaded.
 */
const _wrapPromise = (promise) => {
  let status = "pending";
  let result;
  let suspender = promise.then(
    (r) => {
      status = "success";
      result = r;
    },
    (e) => {
      status = "error";
      result = e;
    }
  );
  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    },
  };
};

export default class Helper {
  /**
   * Gets a token and a user object. If the token is valid, then the user will be set, otherwise it will be null.
   * @param {object} oUser The intended user info
   * @param {string} sToken The intended jwt token
   * @param {array} aGroups List of groups that this user belongs to 
   */
  static setAuthData(oUser, sToken, aGroups) {
    const bValid = _validateToken(sToken);
    try {
      if (bValid) {
        localStorage.setItem(
          AUTH_USER_KEY,
          JSON.stringify(Helper.checkUserProperties(oUser))
        );
        localStorage.setItem(AUTH_USER_TOKEN_KEY, sToken);
        localStorage.setItem(AUTH_USER_GROUP_KEY, JSON.stringify(aGroups));
      } else {
        localStorage.removeItem(AUTH_USER_KEY);
        localStorage.removeItem(AUTH_USER_TOKEN_KEY);
        localStorage.removeItem(AUTH_USER_GROUP_KEY);
        sessionStorage.removeItem(S3_BASE_URL_KEY);        
      }
      _callUserUpdatedCallbacks();
    } catch (e) {}
  }

  /**
   * Will delete the user and token data
   */
  static removeAuthData() {
    Helper.setAuthData(null, null, null);
  }

  /**
   * Extracts user's properties from user object
   * and will push empty strings instead of undefined properties
   */
  static checkUserProperties(user) {
    let _user = user ? { ...user } : {}; // we use shallow copy to prevent rewriting on user object!
    const keys = Helper.getUserPropertiesNames();
    for (let i in keys) {
      let key = keys[i];
      if (!_user[key]) {
        _user[key] = "";
      }
    }
    return _user;
  }

  /**
   * This function is used for updating user data after a success login.
   * It do the update if and only if there is a valid token already stored.
   * @param {object} oUser The user data
   */
  static updateUser(oUser) {
    let sToken = localStorage.getItem(AUTH_USER_TOKEN_KEY);
    const bValid = _validateToken(sToken);
    try {
      if (bValid) {
        localStorage.setItem(
          AUTH_USER_KEY,
          JSON.stringify(Helper.checkUserProperties(oUser))
        );
      } else {
        localStorage.removeItem(AUTH_USER_KEY);
        localStorage.removeItem(AUTH_USER_TOKEN_KEY);
        sessionStorage.removeItem(S3_BASE_URL_KEY);
      }
      _callUserUpdatedCallbacks();
    } catch (e) {}
  }

  /**
   * Adds a callback function to the array for calling when user gets updated
   * @param {function} callbackFunction The call back function that must be called after user gets updated
   */
  static attachOnUserUpdated(callbackFunction) {
    aUserUpdatedCallbacks.push(callbackFunction);
  }

  /**
   * Removes a callback function from the array of onUserUpdated event
   * @param {function} callbackFunction The call back function that must be called after user gets updated
   */
  static detachOnUserUpdated(callbackFunction) {
    for (let i = 0; i < aUserUpdatedCallbacks.length; i++) {
      let fn = aUserUpdatedCallbacks[i];
      if (fn === callbackFunction) {
        aUserUpdatedCallbacks.splice(i, 1);
        break;
      }
    }
  }

  /**
   * Returns the current user, if a valid token is already set in the session.
   */
  static getUser() {
    let sToken = localStorage.getItem(AUTH_USER_TOKEN_KEY);
    const bValid = _validateToken(sToken);
    if (bValid) {
      let jUser = localStorage.getItem(AUTH_USER_KEY);
      return JSON.parse(jUser);
    }
    return null;
  }

  /**
   * Returns the current user's groups, if a valid token is already set in the session.
   */
   static getUserGroups() {
    let sToken = localStorage.getItem(AUTH_USER_TOKEN_KEY);
    const bValid = _validateToken(sToken);
    if (bValid) {
      let jUserGroups = localStorage.getItem(AUTH_USER_GROUP_KEY);
      return JSON.parse(jUserGroups);
    }
    return null;
  }

  /**
   * Returns the current jwt token.
   */
  static getToken() {
    let sToken = localStorage.getItem(AUTH_USER_TOKEN_KEY);
    const bValid = _validateToken(sToken);
    if (bValid) {
      return sToken;
    }
    return null;
  }

  /**
   * Stores href for unauth user to use after login
   * @param {string} href
   */
  static setRedirectHref(href) {
    localStorage.setItem(UNAUTH_USER_REDIRECT_HREF, href);
  }

  /**
   * Returns the redirect href
   * @returns {string} the redirect href
   */
  static getRedirectHref() {
    return localStorage.getItem(UNAUTH_USER_REDIRECT_HREF);
  }

  /**
   * Removes the redirect href
   */
  static removeRedirectHref() {
    localStorage.removeItem(UNAUTH_USER_REDIRECT_HREF);
  }

  /**
   * Pops the redirect href and returns it
   */
  static popRedirectHref() {
    let href = localStorage.getItem(UNAUTH_USER_REDIRECT_HREF);
    localStorage.removeItem(UNAUTH_USER_REDIRECT_HREF);
    return href;
  }

  /**
   * Returns the S3 Base URL
   * @returns {object} result of the s3BaseURL api
   */
  static fetchS3BaseURL() {
    const promise = Helper.updateS3BaseURL();
    return {
      result: _wrapPromise(promise),
    };
  }

  /**
   * This function update the base url of S3 for generating public files url in the front-end
   * @returns {Promise} A promise that will resolve after updating the url
   */
  static updateS3BaseURL() {
    return new Promise((resolve, reject) => {
      API.get(S3_API_NAME, "/getbaseurl")
        .then((response) => {
          const {
            body: { url },
          } = response;
          try {
            if (url) {
              sessionStorage.setItem(S3_BASE_URL_KEY, url);
            } else {
              sessionStorage.removeItem(S3_BASE_URL_KEY);
            }
          } catch (err) {
            console.error("Couldn't set the S3 base url");
            let result = { ...err };
            result.url = "";
            reject(result);
          }
          resolve({ url });
        })
        .catch((err) => {
          console.error("Couldn't get the S3 base url");
          let result = { ...err };
          result.url = "";
          reject(result);
        });
    });
  }

  /**
   * Returns the current S3 base url.
   */
  static getS3BaseURL() {
    const sURL = sessionStorage.getItem(S3_BASE_URL_KEY);
    return sURL;
  }

  /**
   * It returns a regular expression for email validation.
   * If the bWhole be passed true, then it will match the whole string.
   * If the bWhitespace be passed true, then it will allow white space around the email
   * @param {boolean} bWhole if pass true then ^$ will be added to regex to match the whole string
   * @param {boolean} bWhitespace if pass true then whitespace will be allowed around it
   */
  static emailRegex(bWhole, bWhitespace) {
    /*eslint no-control-regex: "off"*/
    let sRegex =
      "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])";
    if (bWhitespace === true) {
      sRegex = "\\s*" + sRegex + "\\s*";
    }
    if (bWhole === true) {
      sRegex = "^" + sRegex + "$";
    }
    let oRegex = new RegExp(sRegex, "i");
    return oRegex;
  }

  /**
   * Gets a GraphQL query and exchanges its items
   * @param {string} query the query string that we have to modify its items section
   * @param {array} items the items that must be replaced
   */
  static replaceItemsInQuery(query, items) {
    const regex = /(items\s*)({(\s*[^{]*{[^}]*})*(\s*[^}]*)*})+/gm;
    let m = regex.exec(query);
    if (m !== null && m.length > 0) {
      return query.replace(m[2], items);
    }
    return query;
  }

  /**
   * It applies a search with a starting position
   * @param {string} str The string that we must apply search on
   * @param {string} regex The regualr expression string
   * @param {number} startpos The position for starting search
   * @returns {number} -1 if not found; > 0 if found a match
   */
  static regexIndexOf(str, regex, startpos) {
    if (startpos > str.length) return -1;
    var indexOf = str.substring(startpos || 0).search(regex);
    return indexOf >= 0 ? indexOf + (startpos || 0) : indexOf;
  }

  /**
   * Gets a GraphQL query and remove an extension object in the query
   * @param {string} query the query string that we have to modify its items section
   * @param {string} item the item's name that must be removed
   */
  static removeAllExtensionInQuery(query, item) {
    let i = query.indexOf(item);
    for (; i < query.length && query.charAt(i) !== "{"; i++);
    const start = i + 1;
    const regexStr = "(\\s*[^{\\s]+\\s*{)";
    do {
      let i = Helper.regexIndexOf(query, regexStr, start);
      if (i < 0) {
        break;
      }
      let cutStart = i;
      let counter = -1;
      for (; i < query.length && counter !== 0; i++) {
        let ch = query.charAt(i);
        if (counter < 0) {
          if (ch === "{") {
            counter = 1;
          }
        } else {
          if (ch === "{") {
            counter++;
          } else if (ch === "}") {
            counter--;
          }
        }
      }
      query = query.substr(0, cutStart) + query.substr(i);
    } while (true);
    return query;
  }

  /**
   * Gets a GraphQL query and remove an extension object in the query
   * @param {string} query the query string that we have to modify its items section
   * @param {string} item the item's name that must be removed
   */
  static removeExtensionInQuery(query, item) {
    const regexStr = "(\\s*" + item + "\\s*{)";
    let start = 0;
    do {
      let i = Helper.regexIndexOf(query, regexStr, start);
      if (i < 0) {
        break;
      }
      let cutStart = i;
      let counter = -1;
      for (; i < query.length && counter !== 0; i++) {
        let ch = query.charAt(i);
        if (counter < 0) {
          if (ch === "{") {
            counter = 1;
          }
        } else {
          if (ch === "{") {
            counter++;
          } else if (ch === "}") {
            counter--;
          }
        }
      }
      query = query.substr(0, cutStart) + query.substr(i);
    } while (true);
    return query;
  }

  /**
   * Gets a GraphQL query and replace an extension object in the query
   * @param {string} query the query string that we have to modify its items section
   * @param {string} item the item's name that must be replace
   * @param {string} replacement the item's name that must be place
   */
  static replaceExtensionInQuery(query, item, replacement) {
    const regexStr = "(\\s*" + item + "\\s*{)";
    let start = 0;
    do {
      let i = Helper.regexIndexOf(query, regexStr, start);
      if (i < 0) {
        break;
      }      
      let cutStart = i;
      let counter = -1;
      for (; i < query.length && counter !== 0; i++) {
        let ch = query.charAt(i);
        if (counter < 0) {
          if (ch === "{") {
            counter = 1;
          }
        } else {
          if (ch === "{") {
            counter++;
          } else if (ch === "}") {
            counter--;
          }
        }
      }
      start = cutStart + replacement.length;
      query = query.substr(0, cutStart) + replacement + query.substr(i);
    } while (true);
    return query;
  }

  /**
   * Returns an array of strings that contains user's properties' names
   * @returns {array} Array of strings
   */
  static getUserPropertiesNames() {
    const properties = [
      "address",
      "birthdate",
      "email",
      "family_name",
      "gender",
      "given_name",
      "locale",
      "middle_name",
      "name",
      "nickname",
      "phone_number",
      "picture",
      "preferred_username",
      "profile",
      "zoneinfo",
      "updated_at",
      "website",
    ];
    return properties;
  }
}
